@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

body {
  margin: 0px;
  padding: 0px;
}

html {
  scrollbar-gutter: stable;
}
